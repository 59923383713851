import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { VideoPlayer } from '../../components/common/video-player'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SectionStores } from '../../components/section-stores'
import { SEO } from '../../components/seo'

const SmartView = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'features.smartView' })

  return (
    <>
      <SEO title={t('title')} />
      <div className="flex flex-col h-screen justify-between">
        <Navigation />
        <ContentMain>
          <HeaderTitle title={t('title')} />
          <ContentSubPage isFull>
            <div className="grid grid-cols-12 gap-y-12 lg:gap-x-24 my-20">
              <div className="col-span-12 lg:col-span-5 lg:place-self-center">
                <p>{t('1.text1')}</p>
                <p>{t('1.text2')}</p>
              </div>
              <div className="col-span-12 lg:col-span-7 rounded-xl">
                <VideoPlayer url={t('1.videoUrl')} />
              </div>
              <div className="col-span-12 bg-gray-200 w-full h-1" />
              <div className="col-span-12 lg:col-span-5 lg:place-self-center">
                <p>{t('2.text1')}</p>
                <p>{t('2.text2')}</p>
              </div>
              <div className="col-span-12 lg:col-span-7">
                <VideoPlayer url={t('2.videoUrl')} />
              </div>
            </div>
          </ContentSubPage>
          <SectionStores className="pt-8 pb-28" />
        </ContentMain>
        <Footer />
      </div>
      <CookiesBox />
    </>
  )
}

export default SmartView

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
